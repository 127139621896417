import React from "react"
import { Link } from "gatsby"

const Button = ({ children, href, target, rel, className }) => {
  const internal = /^\/(?!\/)/.test(href)

  const classes = [
    "inline-block",
    "px-6",
    "py-3",
    "text-white",
    "bg-gold",
    "text-lg",
    "hover:bg-gold-dark",
    "transition-transform",
    "duration-300",
    "transform",
    "uppercase",
    "font-thin",
  ].join(" ")

  // if internal, use Link component
  if (internal) {
    return (
      <Link className={`${classes} ${className}`} to={href}>
        {children}
      </Link>
    )
  } else {
    // if external, use anchor tag
    return (
      <a
        className={`${classes} ${className}`}
        href={href}
        target={target}
        rel={rel}
      >
        {children}
      </a>
    )
  }
}

export default Button
