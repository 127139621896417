import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/Button"
import ImageCarousel from "../components/img/ImageCarousel"
import LogoStream from "../components/img/LogoStream"
import { StaticImage } from "gatsby-plugin-image"

const ChristmasPage = () => {
  const images = [
    {
      src: "/banners/christmas-page-banner.jpg",
      alt: "Give The Gift Of Merry Moments Together",
      link: null,
    },
  ]

  return (
    <Layout>
      <Seo
        title={`Give The Gift Of Merry Moments Together`}
        description={`We have the perfect gift to spoil your loved ones with this year. Our gift cards can be used in over 1600 pubs, bars, restaurants, and hotels across the UK.`}
      />

      <ImageCarousel images={images} />

      <LogoStream />

      <div className="content-wrapper">
        <div className="main">
          <h1>Give The Gift Of Merry Moments Together</h1>

          <p>
            We have the perfect gift to spoil your loved ones with this year.
            Our gift cards can be used in over 1600 pubs, bars, restaurants, and
            hotels across the UK, including your favourite brands such as All
            Bar One, Miller & Carter, Toby Carvery, Harvester, Vintage Inns,
            Browns Brasserie and Sizzling Pubs.
          </p>

          <p>
            Our gift cards are available via email as a digital gift voucher and
            all can be personalised for that added extra special touch. Or you
            can order a physical gift card via post that comes with a
            personalised Christmas greetings card.
          </p>

          <p>
            Every gift card is valid for 24-months after purchase, giving the
            recipient plenty of time to spend their gift card.
          </p>

          <Button
            href="https://diningout.cashstar.com/"
            rel="noreferrer"
            target="_blank"
          >
            Buy a gift card
          </Button>

          <StaticImage
            src="../../static/general/christmas-grid.jpg"
            alt="Give The Gift Of Merry Moments Together"
            className="w-full mt-4"
          />
        </div>
      </div>
    </Layout>
  )
}

export default ChristmasPage
